export default {
  title: 'Workspace Settings',
  tab: {
    account: 'Account',
    settings: 'Settings',
    general: 'General',
    members: 'Members',
    theme: 'Theme',
    pricing: 'Pricing',
    billing: 'Billing',
    notifications: 'Notifications',
    integrations: 'Integrations',
    youtube: 'YouTube',
    intercom: 'Intercom',
    salesforce: 'Salesforce',
    security: 'Security',
    stripe: 'Stripe',
    elevenlabs: 'Elevenlabs',
    email:'Email Notifications'
  },
  workspace: {
    heading: 'Workspace Settings',
    display_name: 'Workspace Name',
    description: 'This is the name for your workspace',
    placeholder: 'Yoda',
    error: 'Please enter a product space name',
    min_length_error: 'Workspace name should have a minimum of 4 characters',
    domain_name: 'Domain Name',
    domain_description: 'This is the domain name for your workspace',
    language: 'Default Language',
    language_description:
      'This is the default language for which you can add voice overs.',
    language_contact:
      'Your current plan supports adding voice overs in only one language. You cannot change it later.',
    language_contact_1:
      'Your current plan supports adding voice overs in only one language.',
    language_contact_2: ' to change this language or ',
    language_contact_3: ' to use multiple languages',
    api_key_label: 'API key',
    api_key_description:
      'Use the below key to access our APIs. Make sure that this key is stored at your server side in a secure manner.',
    api_key_regenerate_description:
      'You can regenerate your API key. After regeneration, your old key will be accessible for 24 hours. Please contact us if you wish to disable the key immediately.',
    regenerate_label: 'Regenerate API key',
    mau: {
      1: `You've used up `,
      2: ' MAUs'
    }
  },
  account: {
    heading: 'Account Settings',
    label: 'Name',
    change_password: 'Update password?',
    update_password: 'Update password',
    bio: 'Bio',
    password: {
      old_label: 'Old password',
      new_label: 'New password',
      success: 'Your password has been changed successfully.'
    },
    designation: 'Designation',
    change_photo: 'Change photo',
    profile_image_desc: 'Upto 1200 x 1200',
    email_notifications: 'Receive engagement notifications via email'
  },
  theme: {
    logo: {
      label: 'Workspace Logo',
      description:
        'The logo you uploaded will be used in displaying intro and outro slides of the video'
    },
    color: {
      label: 'Theme',
      description: 'The player will be customised to the brand color selected',
      hex: 'Or hex'
    }
  },
  guide_theme: {
    title: 'Guide Theme',
    background_title: 'Background Color',
    background_description:
      'The guide background will be customised to the color selected',
    text_title: 'Font Color',
    text_description: 'The guide font will be customised to the color selected',
    button_title: 'Button Color',
    button_description:
      'The guide button will be customised to the color selected',
    spotlight_title: 'Spotlight Color',
    spotlight_description:
      'The guide spotlight will be customised to the color selected'
  },
  transfer_owner: {
    action: 'Transfer workspace ownership',
    title: 'Transfer Workspace Ownership',
    description: 'This action is permanent and can only be reversed by the new owner.',
    save_button: 'Confirm Transfer',
    success: 'Owner Transfered Successfully'
  },
  billing: {
    manage_payments: {
      label: 'Manage your payment details',
      description:
        'You can view and modify the payment method that is used for payments.',
      cta: 'Manage'
    },
    invoices: {
      label: 'Invoices',
      description: 'View and download your payment history.',
      cta: 'View Invoices'
    },
    cancel_subscription: {
      label: 'Cancel subscription',
      description:
        'You can cancel your subscription. You will not be charged further after cancelling the subscription. You can access the workspace till the end of trial or till the end of the current billing month',
      reactivate_description: 'Subscription cancellation: %{date}',
      cta: 'Cancel subscription',
      reactivate_cta: 'Reactivate cancelled subscription'
    },
    billing_details: {
      label: 'Manage your billing details',
      description:
        'You can view and modify the billing details.',
      cta: 'Manage'
    }
  },
  notifications: {
    email_digest: {
      title: 'Email Digest - Video Engagements',
      description:
        'Choose the frequencies, on when you wish to recieve video engagement digest emails.',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly'
    },
    academy_email_digest: {
      title: 'Email Digest - Academy',
      description:
        'Choose the frequencies, on when you wish to recieve academy engagement digest emails.',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly'
    },
    no_notifications: 'No notifications found'
  },
  security: {
    enable: 'Enable SAML',
    sso: 'Single Sign-On',
    login_url: 'Login Url',
    saml_certificate: 'SAML Certificate',
    logout_url: 'Logout URL (Optional)',
    text: {
      saml_saved: 'SAML Config Saved',
      saml_disabled: 'SAML Disabled',
      invalid_login_url: 'Please enter valid login url',
      invalid_saml_certificate: 'Please enter valid SAML Certificate',
      invalid_logout_url: 'Please enter valid logout url',
      save_error: 'Please correct the errors before saving.',
      enable_desc:
        'Once enabled users will be able to login only with Single Sign-On.',
      login_url_desc: 'Users will be redirected to this URL to login.',
      saml_certificate_desc:
        'SAML X.509 certificate to authenticate with your Identity Provider.',
      logout_url_desc:
        'Users will be redirected to this URL when they logout from app.'
    }
  },
  current_plan: 'You are currently on %{plan_name} plan.',
  next_billing_on: 'Your next billing is on %{next_billing_date}.',
  members: {
    label: 'Email',
    placeholder: 'John@yoda.io',
    user: 'User',
    role: 'Role',
    send_digest_emails: 'Send Digest Emails',
    status: 'Status',
    actions: 'Actions'
  },
  invites: {
    title: 'Pending Invites',
    re_invite: 'Re-invite',
    revoke: 'Revoke'
  },
  pricing: {
    old_pricing: `You are currently in our old pricing structure. Kindly <a href="mailto:support@trainn.co"> contact us </a> to unlock the advantages of our new pricing.`,
    view_features: 'View Features',
    next_billing: 'Your Next Billing is on',
    free: {
      title: 'Free',
      caption:
        ' For new teams with big ideas, begin your video creation journey here.',
      1: 'Unlimited Walkthrough Videos',
      2: 'Unlimited Instant Videos',
      3: '5 collaborators',
      4: 'Video Sharing',
      5: 'Video Embed',
      6: 'Video level analytics',
      7: 'up to 100 MAUs (Monthly unique learners who watch the videos)',
      8: 'Add voice-overs in only one language',
      9: 'In-app Widget(coming soon)'
    },
    standard: {
      title: 'Standard Plan',
      caption:
        'For teams who need better workflows and track customer level analytics.',
      1: 'Unlimited Videos',
      2: 'Unlimited Learning Paths',
      3: 'Unlimited Collaborator',
      4: 'Knowledge Hub',
      5: 'Detailed Analytics',
      6: '%{allowed_minutes} engagement minutes every month',
      note: `Additional $1 for %{overage_minutes} engagement minutes`
    },
    growth: {
      title: 'Studio',
      caption:
        'Boost your pipeline with comprehensive viewer analytics and integrations that you love.',
      1: 'Everything in Free Plan',
      2: 'Learners module',
      3: 'Learners analytics',
      4: 'Up to 500 MAUs*',
      5: 'Intercom widget',
      6: 'Youtube Integration',
      7: 'Remove Trainn Branding',
      8: 'Unlimited Collections',
      9: 'Unlimited Collaborator',
      10: 'Add Voice-over in multiple languages',
      11: 'Custom Domain'
    },
    premium: {
      title: 'Premium',
      caption:
        'Create high-impact video academy for complete digital transformation.',
      1: 'Everything in Premium Plan',
      2: 'Up to 2000 MAUs*',
      3: 'Video Academy',
      4: 'SSO Authentication',
      5: 'Academy Personalisation',
      6: 'Export as PDF',
      7: 'Download Videos',
      8: 'Add watermark in all videos'
    },
    enterprise: {
      title: 'Enterprise Plan',
      caption: 'For teams who want to customized settings',
      1: 'Custom Pricing based on MAUs',
      2: 'Custom Roles & Permissions',
      3: 'Build a custom voice and use for all videos',
      4: 'Dedicated account manager',
      5: 'Custom Contract'
    },
    addon: {
      tag: 'Addon',
      brand: {
        title: 'Brand Personalisation',
        features: {
          1: 'Custom Domain',
          2: 'Remove Trainn Branding',
          3: 'Logo Watermarking'
        }
      }
    },
    custom: {
      standard_title: 'Standard Plan',
      standard_caption: 'Everything you need to train your customers',
      enterprise_title: 'Enterprise Plan',
      enterprise_caption:
        'For organizations that need additional control and end to end support',
      tagline: 'Only pay for what you need. Our pricing is based on,',
      pricing: {
        1: 'Number of Monthly Active Users',
        2: 'Number of Collaborators',
        3: 'Number of Videos'
      },
      features: {
        1: 'Academy with Learning Flow',
        2: 'Detailed Analytics',
        3: 'Custom Domain',
        4: 'Brand Personalization',
        5: 'Custom Roles & Permissions',
        6: 'Dedicated Account Manager',
        7: 'Custom contract',
        everything: 'Everything in Standard'
      }
    }
  },
  integrations: {
    title: 'Integrations - Your workflow + Trainn',
    description: 'Organize and manage all your integrations.',
    connect: 'Connect',
    disconnect: 'Disconnect',
    contact: 'Contact Support',
    youtube: {
      contact_support: `Kindly <a href="mailto:support@trainn.co"> contact us </a> to enable youtube integration for your workspace.`,
      title: 'YouTube Integration',
      desc:
        'Share your videos beyond your application. Connect your YouTube channel.',
      connected_desc: `Your YouTube channel <b>%{name}</b> has been connected to Trainn.`,
      channel_name: 'Channel Name'
    },
    intercom: {
      contact_support: `Kindly <a href="mailto:support@trainn.co"> contact us </a> to enable intercom integration for your workspace.`,
      title: 'Intercom Integration',
      desc:
        'Intercom makes communication with customers simple, personal and fun. Integrate Trainn with Intercom to send help videos to select customers, and more. Offer a flawless support interaction experience, and make the most of the relationship with your customers.',
      outer_desc:
        'Share Trainn videos easily from your Intercom inbox and offer a flawless support interaction experience.',
      connected_desc:
        'You Intercom workspace %{name} has been connected to Trainn.',
      channel_name: 'Workspace Name',
      connect_from_appstore:
        'You can easily connect the Trainn application to your Intercom from the Intercom app store.',
      map_customers_title: 'Map customers from Intercom to Trainn',
      map_customers_desc:
        'Choose a unique field to create and sync customers from Intercom to Trainn.',
      map_customers_via_email: 'Use Email',
      map_customers_via_userid:
        "If unchecked Intercom's User Id will be used as unique identifier",
      content_title: 'Manage videos that has to be displayed in Intercom',
      content_desc:
        'Videos added here will be available in your Intercom widget'
    },
    salesforce: {
      title: 'Salesforce Integration',
      outer_desc:
        'Connect and sync learner data from Trainn onto your Salesforce app.',
      connect_section_title: 'Connect with Salesforce',
      connect_section_desc:
        'Sync learner data like courses enrolment, analytics, and more',
      instance_url: 'Salesforce Instance URL',
      installed_by: 'Installed By',
      add_package: 'Add Package',
      add_package_title: 'Add Trainn Package to Salesforce',
      add_package_desc:
        'The package will add Custom Objects and fields to your contacts.',
      add_package_content:
        'The package will add Custom Objects and fields to your contacts.',
      details_title: "What you'll need",
      details_point1: 'A Salesforce account with admin permissions',
      details_point2: 'A Salesforce edition with API access',
      sync_setting_title: 'Sync Settings',
      sync_setting_desc:
        'Map the right fields from Trainn with the contacts in your Salesforce',
      create_contact_if_missing: 'Create Contact in Salesforce if Missing',
      create_contact_if_missing_desc:
        'If the learner is missing in salesforce contact, Create Contact in salesforce fo the learner.',
      already_added: 'Added package already?',
      refresh: 'Refresh to update',
      before_installation: {
        title: 'You have not added the Trainn Package',
        desc:
          'This is essential to sync Trainn data to your contacts in Salesforce'
      },
      package_installed: 'Trainn package installed'
    },
    stripe: {
      title: 'Stripe Connect',
      outer_desc:
        'Connect your stripe account and start creating paid courses.',
      connect_section_title: 'Connect with Stripe',
      connect_section_desc:
        'Connect your stripe account and start creating paid courses.',
      connected_desc: `Your stripe account has been connected to Trainn.`,
      connect_details: 'Stripe Connect Details',
      charges: 'Charges',
      payouts: 'Payouts',
      currency: 'Currency',
      country: 'Country',
      business_name: 'Business Name',
      email: 'Email'
    },
    elevenlabs: {
      title: 'ElevenLabs Integration',
      outer_desc: 'Connect your ElevenLabs account and use the voices in Trainn',
      connected_desc: 'Your ElevenLabs account has been connected to Trainn',
      selected_voices: 'Selected Voices',
      select_voice: 'Add Voice',
      connect: 'Connect',
      voice_id: 'Add the elevenlabs id of the voice which you wish to add.',
      add: 'Add'
    }
  },
  disabled_reason: `%{feature} does not match the allowed configuration`,
  access_limit: {
    collaborators:
      'Need more hands? Upgrade now and add more creators from your team.',
    collection:
      'Oops! Seems you’ll need a higher plan to start creating your collection!',
    download_images:
      'Looking to download images? You’ll need to upgrade your plan.',
    download_pdf: 'Looking to download PDF? You’ll need to upgrade your plan.',
    upload_videos:
      'Want to upload your own videos? No problem. Upgrade now and get started.',
    insta_videos:
      'Oops! Seems you’ll need a higher plan to start creating instant videos!',
    watermark:
      'Looking to add watermarks to your video? You’ll need to upgrade your plan.',
    hub: 'Oops! Seems you’ll need a higher plan to enable knowledge hub!',
    flow: 'Unlock Flow!',
    learner:
      "Uh-oh!  The Free Plan doesn't include learners. To get access, start free trial for Studio or Premium.",
    learner_group:
      "Uh-oh!  The Free Plan doesn't include learner groups. To get access, start free trial for Studio or Premium.",
    youtube: 'Need to integrate YouTube? No problem! Try Trainn Studio.',
    intercom: 'Need to integrate Intercom? No problem! Try Trainn Studio.',
    academy: 'Uh-oh! Seems you’ll need a higher plan to enable video academy!',
    salesforce: 'Need to integrate Salesforce? Contact Support.',
    stripe: 'Need to connect with Stripe?'
  },
  email: {
    title: 'Emails',
    desc: 'Customize and configure emails',
    options: {
      email_settings: 'Email Settings',
      email_notifications: 'Academy Notifications'
    },
    server: {
      smtp_option: 'SMTP Option',
      smtp_option_desc: 'Choose SMTP Option',
      smtp_option_placeholder: 'Choose SMTP Option',
      from_email: 'From Email',
      from_email_desc: 'From Email',
      from_email_placeholder: 'Enter From Email',
      from_name: 'From Name',
      from_name_desc: 'Display Name',
      from_name_placeholder: 'Enter From Name',
      reply_to_name_placeholder: 'Enter Reply to Name',
      reply_to_email_placeholder: 'Enter Reply to Email',
      reply_to_name: 'Reply to Name',
      reply_to_email: 'Reply to Email',
      address: 'SMTP Server',
      address_desc: 'SMTP Server',
      address_placeholder: 'Enter Server Address',
      username: 'Username',
      username_desc: 'Credential Username',
      username_placeholder: 'Enter Username',
      password: 'Password',
      password_desc: 'Credential Password',
      password_placeholder: 'Enter Password',
      port: 'Port Number',
      port_desc: 'Port Number of server',
      port_placeholder: 'Enter Port Number',
      tls: 'TLS',
      tls_desc: 'Enable TLS',
      api_key_placeholder: 'Enter API Key',
      api_key: 'API Key'
    }
  }
};
