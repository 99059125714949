export default {
  view_summary: 'View Summary',
  summary_place_holder: 'Add a summary to your guide',
  error: {
    processing: {
      description:
        'We regret to inform you that we encountered some errors while processing this recording and could not proceed further',
      record: 'Record Again'
    }
  },
  delete: {
    title: 'Are you sure you want to delete this guide?',
    description:
      'This guide will be deleted forever and any links associated with this guide will not be available anymore.'
  },
  crop_frame: {
    save: 'Crop Image',
    update: 'Update Image',
    clear: 'Revert to Original',
    title: 'Crop Image',
    description:
      'Select a specific portion of the image and we will remove everything outside of it.',
    apply_desc: 'Apply this change to all clips?'
  },
  step: {
    reorder: 'Reorder',
    delete: 'Delete',
    delete_title: 'Are you sure you want to delete this step?',
    multi_delete_title: 'Are you sure you want to delete these steps?',
    empty_deletion: 'Please select atleast one step to delete'
  },
  comment: {
    you: 'You',
    cancel: 'cancel',
    title: 'Comments',
    description: "Visible only to you and your learners.",
    discussion_desc: 'Visible only to your teammates.', 
    comments: 'Comments',
    discussion: 'Discussion',
    empty_discussion: 'No one is discussing here.',
    empty_comments: 'No one has commented yet',
    new_comment: 'Start New Discussion',
    new_comment_desc: 'This will be visible only to your teammates',
  },
  transition_speed:{
    title: 'Guide Transition Speed Settings',
    description: 'Optimize your guide viewing by setting a default transition speed.',
    button: 'Guide Transition Speed'
  }
}